<template>
    <div :class="`${$isMobile() ? '' : 'v'}h-100 flex-fill position-relative d-flex flex-column auth-page`" style="overflow-y: scroll;">

        <img v-if="!$isMobile()" src="@/static/dog-left-right.png" class="position-absolute w-100" :style="`max-width:150px;bottom:120px`" />
        <img v-if="!$isMobile()" src="@/static/dog-right-left.png" class="position-absolute w-100" style="max-width:150px;right:0;top: 120px;" />

        <!-- <img src="@/static/dog-left-right.png" class="position-absolute w-100" style="max-width:150px;bottom:0px" /> -->
        <div class="position-relative">
            <img src="@/static/dog-right-left.png" class="position-absolute w-100" style="max-width:150px;right:0;top: 0px;" />

            <div class="position-absolut" style="top:30px" v-if="$isMobile()">
                <img src="@/static/logo.png" style="max-width:250px" />
                <h1 class="text-white h5 ml-2" style="font-weight: normal;margin-top:-1em">All Your Pet Needs In Your Hand</h1>
            </div>
        </div>

        <div :class="`w-100 ${$isMobile() ? 'mt-3' : 'mx-auto mt-auto'}`" :style="`bottom: 0 !important;${$isMobile() ? '' : 'max-width:500px'}`">
            <div class="px-3">
                <h3 class="text-white font-weight-bolder h2 mb-2">Create Account</h3>
                <div class="input-group">
                    <span class="input-group-text"><i class="bx bxs-user-badge"></i></span>
                    <input type="text" class="form-control" placeholder="Your Name" v-model="input_data.user_name" maxlength="40">
                </div>
                <div class="text-white" style="margin-top: .5em;">*Name is atleast 8 character</div>
                <div class="badge badge-danger text-white" v-if="!valid.user_name && isSubmit" style="margin-top: .5em;">Username not valid, atleast 8 character</div>
                
                <div class="input-group mt-1">
                    <span class="input-group-text"><i class="bx bxs-user-circle"></i></span>
                    <input type="text" class="form-control" placeholder="Email" v-model="input_data.user_email">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.user_email && isSubmit" style="margin-top: .5em;">Email not valid</div>
                
                <div class="input-group mt-1">
                    <span class="input-group-text"><i class="bx bxs-lock-alt"></i></span>
                    <input type="password" class="form-control" placeholder="Password" v-model="input_data.user_password">
                </div>
                <div class="text-white" style="margin-top: .5em;">*Password is atleast 8 character</div>
                <div class="badge badge-danger text-white" v-if="!valid.user_password && isSubmit" style="margin-top: .5em;">Password not valid</div>

                <div class="input-group mt-1">
                    <span class="input-group-text"><i class="bx bxs-lock-alt"></i></span>
                    <input type="password" class="form-control" placeholder="Retype Password" v-model="re_type">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.password_confirm && isSubmit" style="margin-top: .5em;">Password not match</div>

                <div class="input-group mt-1">
                    <span class="input-group-text"><i class="bx bx-globe"></i></span>
                    <select class="form-control" style="height:50px" v-model="input_data.country">
                        <option value="indonesia">Indonesia</option>
                        <option value="singapore">Singapore</option>
                        <option value="thailand">Thailand</option>
                    </select>
                </div>
                <div class="mt-1">
                    <label class="text-white h4">Register As <small>(this can be changed later)</small></label>
                    <div class="d-flex align-items-center">
                        <div class="form-check form-check-inline d-flex align-items-center" style="gap:5px">
                            <input class="form-check-input" type="checkbox" style="height:15px;width:15px" id="service-provider" v-model="input_data.is_service_provider">
                            <label class="form-check-label text-white h4 mb-0" for="service-provider">Service Provider</label>
                        </div>
                        <div class="form-check form-check-inline d-flex align-items-center" style="gap:5px">
                            <input class="form-check-input" type="checkbox" style="height:15px;width:15px" id="pet-owner" v-model="input_data.is_pet_owner">
                            <label class="form-check-label text-white h4 mb-0" for="pet-owner">Pet Owner</label>
                        </div>
                    </div>

                    <div class="badge badge-danger text-white" v-if="!valid.user_role && isSubmit" style="margin-top: .5em;">Choose one of type</div>
                </div>

                <hr />

                <div class="d-flex flex-wrap align-items-center mt-1" style="gap:10px">
                    <div class="text-white mr-auto h5 mb-0 font-weight-normal">
                        Already Have An Account?
                        <router-link to="/login" class="font-weight-bolder text-white">Sign In Here!</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div :class="`pt-1 ${$isMobile() ? 'mt-auto mb-2' : 'mb-auto mx-auto'} w-100 d-flex flex-column px-3 justify-content-center`" :style="`gap:10px;${$isMobile() ? '' : 'max-width:500px'}`">
            <button class="btn text-white flex-fill" style="background-color: #187294;height:50px;font-size: 18px;"
                @click="onSubmit()">
                Create Account
            </button>
        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    computed: {
        valid(){
            return {
                user_name : this.input_data.user_name?.length > 7,
                user_email : this.input_data.user_email?.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
                user_password : this.input_data.user_password?.length >= 8,
                password_confirm : this.re_type == this.input_data.user_password,
                user_role : (this.input_data.is_service_provider || this.input_data.is_pet_owner),
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    methods: {
        onSubmit() {
            this.isSubmit = true
            if (this.allValid) {
                let payload = new FormData()
                payload.append('inp[user_name]', this.input_data.user_name)
                payload.append('inp[user_email]', this.input_data.user_email)
                payload.append('inp[user_password]', this.input_data.user_password)
                payload.append('inp[country]', this.input_data.country)
                payload.append('inp[user_role]', this.input_data.is_pet_owner ? 'pet_owner' : 'service_provider')
                payload.append('is_register', true)
                store.dispatch('auth/SaveUser', payload).then(res => {
                    this.$router.push('/login')
                })
            }
        }
    },
    data() {
        return {
            input_data: {
                user_name: null,
                user_email: null,
                user_password: null,
                is_register: true,
                country: 'indonesia',
                is_service_provider: false,
                is_pet_owner: false,
            },
            isSubmit: false,
            re_type: ''
        }
    }
}

</script>

<style>
.auth-page .btn {
    border-radius: 20px;
}

.input-group span {
    border-right: unset;
    border-radius: 10px 0 0 10px;
    font-size: 24px;
    color: #1F9BCD;
}

.input-group input {
    margin-left: 0;
    padding-left: 0;
    border-left: unset;
    border-radius: 0 10px 10px 0;
    height: 50px;
    background-color: white !important;
}</style>